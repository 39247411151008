import {Feature} from 'ol';
import {getRenderPixel} from 'ol/render';
import {fromExtent} from 'ol/geom/Polygon';

export class TileProcessor {

    static debug = true
    static tileCounter

    /**
     * This method will produce an eventhandler function that applies a tile Processing function to each loaded tile
     * E.g. you can pass it to a TileSource EventListener "ontileloadend"
     * @param runModelFn a funtion that expects a reference to a HTMLImageElement and returns some result Obejct
     * @param processModelResultsFn a function that consumes the output from the runModelFn. This will be executed but does not expect a return value
     * @returns {onTileLoadEnd}
     */
    static getTileLoadEventHandler(runModelFn, processModelResultsFn, map, detectedFeatureLayer, tileSource, processedTileCache) {
        const onTileLoadEnd = async (event) => {
            const {tile} = event;            
            // console.log(tile)
            // console.log(tileSource.tileGrid.getOrigin(), tileSource.tileGrid.getResolution(tile.tileCoord[0]), tileSource.tileGrid.getTileSize(tile.tileCoord[0]))
            // console.log(tileSource.tileGrid.getTileCoordExtent(tile.tileCoord))
            const tileExtent = tileSource.tileGrid.getTileCoordExtent(tile.tileCoord)
            // event is also fired on abort and not having an tile
            if (tile) {
                TileProcessor.tileCounter++
                try {
                    if(! processedTileCache.hasTile(tile.tileCoord)){
                        // add to tile cache
                        processedTileCache.addTile(tile.tileCoord)
                    
                    
                        const image = tile.getImage();
                        const result = await runModelFn(image.src);
                        processModelResultsFn(result);

                        const resolution = tileSource.tileGrid.getResolution(tile.tileCoord[0])

                        // find each image's 
                        // const bottomLeftCoord = [tile.tileCoord[1], tile.tileCoord[2]]
                        const [left, bottom, right, top] = tileExtent
                        const leftBottomCoord = [left, bottom ]

                        // send the result to the be processed and rendered on the map
                        TileProcessor.extractModelResponse(result, resolution, leftBottomCoord, detectedFeatureLayer)
                    }
                } catch (e) {
                    processModelResultsFn(e)
                }
            }
            console.log('Number of Tile = ', TileProcessor.tileCounter)
        }
        return onTileLoadEnd;
    }

    static getClickedBoxEventHandler(runModelFn, processModelResultsFn, width, height, sourceLayer, boxLayer, detectedFeatureLayer, tensorFormat) {
        return (event) => {

            console.log('>>> click ', event)

            const map = event.map;
            const resolution = map.getView().getResolution()

            // Get top-left and right-bottom pixels
            let [x, y] = event.pixel
            let left = Math.round(x - (width / 2))
            let bottom = Math.round(y + (height / 2))

            let right = left + width
            let top = bottom - height
            let topLeft = [ top, left ]
            const bottomLeftCoord = map.getCoordinateFromPixel([left, bottom])
            const topRightCoord = map.getCoordinateFromPixel([right, top])

            const topLeftCoord = map.getCoordinateFromPixel([left, top])

            const boxPoly = fromExtent([...bottomLeftCoord, ...topRightCoord])

            // console.log('>>> boxPoly ', boxPoly)
            // console.log(left, map.getPixelFromCoordinate(bottomLeftCoord))

            const boxFeature = new Feature(boxPoly)

            // add polygon to box layer
            boxLayer.getSource().addFeature(boxFeature)

            sourceLayer.once('postrender', async function (event) {
                // canvas pixel transformation
                // console.log(event.inversePixelTransform)
                const scaleX = event.inversePixelTransform[0];
                const scaleY = event.inversePixelTransform[3];

                const scaledWidth = width * scaleX;
                const scaledHeight = height * scaleY;

                const [rx,ry] = getRenderPixel(event, [x, y])

                let rleft = Math.round(rx - (scaledWidth / 2))
                let rbottom = Math.round(ry + (scaledHeight / 2))
                let rright = rleft + scaledWidth
                let rtop = rbottom - scaledHeight

                const layerContext = event.context
                const outputCanvas = document.createElement('canvas');
                const size = [width, height];
                outputCanvas.width = size[0];
                outputCanvas.height = size[1];
                const mapContext = outputCanvas.getContext('2d');

                let imageData = layerContext.getImageData(rleft, rtop, scaledWidth, scaledHeight)

                const imgBitmap = await createImageBitmap(imageData);
                mapContext.drawImage(imgBitmap, 0, 0, width, height)
                console.log('>>> getClickedBoxEventHandler >>> extracted image ')
                console.log(outputCanvas.toDataURL())

                //run tileProcessinfFunction
                const result = await runModelFn(outputCanvas.toDataURL());
                processModelResultsFn(result);

                // send the result to the be processed and rendered on the map
                TileProcessor.extractModelResponse(result, resolution, bottomLeftCoord, detectedFeatureLayer)
            });

            map.renderSync();

        }
    }

    static extractModelResponse(result, resolution, offset, detectedFeatureLayer) {
        // console.log('>>> extractModelResponse ',result, resolution, offset, detectedFeatureLayer)

        // check for num_dets
        // console.log(result.num_dets.data)
        if(result.num_dets.data[0] === 0n)
            return

        const numOfDetects = Number(result.num_dets.data[0])
        
        // console.log(result.det_boxes.data)
        console.log('numOfDetects = ', numOfDetects)

        // get each feature's bbox
        for(let i = 0, j = 0; i < Number(result.det_boxes.data.length) - 4; i = i + 4, j++) {
            // get individual bbox pixel values
            // mulitple the pixel by resolution
            // add the offset to the coord
            let left = result.det_boxes.data[i] * resolution + offset[0]
            let top = ((result.det_boxes.data[i + 1] - 255) * -1) * resolution + offset[1]
            let right = result.det_boxes.data[i + 2] * resolution + offset[0]
            let bottom = ((result.det_boxes.data[i + 3] - 255) * -1) * resolution + offset[1]
            
            // const topLeftCoord = map.getCoordinateFromPixel([top, left])
            // const bottomRightCoord = map.getCoordinateFromPixel([bottom, right])
            const boxPoly = fromExtent([left, top, right, bottom])

            // const bottomLeftCoord = map.getCoordinateFromPixel([left, bottom])
            // const topRightCoord = map.getCoordinateFromPixel([right, top])
            // const boxPoly = fromExtent([...bottomLeftCoord, ...topRightCoord])

            // console.log('bottomLeftCoord = ', bottomLeftCoord)
            // console.log('topRightCoord = ', topRightCoord)
            // console.log('boxPoly = ', boxPoly)            

            // add eachFeature to detectedFeatureLayer
            const eachFeature = new Feature(boxPoly)
            eachFeature.set('confidenceScore', Number(result.det_scores.data[j]).toFixed(2))
            detectedFeatureLayer.getSource().addFeature(eachFeature)
        }        
        // prepare each feature with all meta info as property

        // prepare the geojson 


    }

    static getTileLoadEventHandlerWithWorkers(processModelResultsFn, map, detectedFeatureLayer, tileSource, processedTileCache, model, backendMode, tensorFormat) {
        // console.log('getTileLoadEventHandlerWithWorkers model ', model)
        const numWorkers = navigator.hardwareConcurrency - 2 || 4
        let workerStatus = []
        let workers = []
        let tiles = []

        for (let i = 0; i < numWorkers; i++) {
            let worker = new Worker('./worker.js', { type: "module" })
            workers.push(worker)
            workerStatus.push(false); // Initialize worker status to false
        }

        const onTileLoadEnd = async (event) => {
            const {tile} = event;
            const tileExtent = tileSource.tileGrid.getTileCoordExtent(tile.tileCoord)
            // event is also fired on abort and not having an tile
            if (tile) {
                TileProcessor.tileCounter++
                // if(TileProcessor.tileCounter < numWorkers )
                try {
                    if(! processedTileCache.hasTile(tile.tileCoord)){
                        // add to tile cache
                        processedTileCache.addTile(tile.tileCoord)

                        const resolution = tileSource.tileGrid.getResolution(tile.tileCoord[0])

                        // find each image's
                        const [left, bottom, right, top] = tileExtent
                        const leftBottomCoord = [left, bottom ]

                        const image = tile.getImage();
                        // get dataUrl of the image
                        const canvas = document.createElement('canvas');

                        // We use naturalWidth and naturalHeight to get the real image size vs the size at which the image is shown on the page
                        canvas.width = 256;
                        canvas.height = 256;

                        // We get the 2d drawing context and draw the image in the top left
                        canvas.getContext('2d').drawImage(image, 0, 0);

                        // Convert canvas to DataURL
                        //const dataURL = canvas.toDataURL();
                        const imgdata = canvas.getContext('2d').getImageData(0, 0, 256, 256).data

                        //console.log('worker NUmber : ',TileProcessor.tileCounter % numWorkers)
                        // const worker = workers[TileProcessor.tileCounter % numWorkers]

                        // Add the tile data to the tiles array
                        tiles.push(imgdata);
                        // console.log('tiles count ', tiles.length)

                        // Find the first available worker
                        const nextWorkerIndex = workerStatus.indexOf(false);
                        // console.log('nextWorkerIndex = ', nextWorkerIndex)

                        if (nextWorkerIndex !== -1) {
                            // Get the next available worker
                            const nextWorker = workers[nextWorkerIndex];

                            // Get the next tile from the queue
                            const nextTile = tiles.shift();
                            // console.log('nextTile ', nextTile)

                            // Set the worker status to true indicating it is now processing a tile
                            workerStatus[nextWorkerIndex] = true;

                            // Send the tile data to the next available worker for processing
                            // console.log('before sending inferenceSession = ', inferenceSession)
                            nextWorker.postMessage([nextTile, model, backendMode, tensorFormat]);

                            nextWorker.onmessage = (e) => {
                                const result = e.data
                                console.log('response from worker ', result)
                                // console.log('onmessage ', worker)

                                // Set the worker status to false indicating it is available
                                let workerIndex = workers.indexOf(nextWorker);
                                // console.log('onmessage workerIndex ', workerIndex)
                                workerStatus[workerIndex] = false;
                                //
                                // Check if there are more tiles to process in the queue
                                if (tiles.length > 0) {
                                    // console.log('workerIndex = ', workerIndex)
                                    const nextTile = tiles.shift();
                                    workerStatus[workerIndex] = true;

                                    try {
                                        nextWorker.postMessage([nextTile, model, backendMode, tensorFormat]);
                                    } catch (e) {
                                        // terminate the defective worker
                                        nextWorker.terminate()
                                        // update the workers array
                                        workers.splice(workers.indexOf(nextWorker), 1)
                                        workerStatus.splice(workerIndex,1)
                                        console.warn('error while posting to existing worker, hence terminated this worker. \n Workers count: ', workers.length)
                                    }
                                } else if(tiles.length === 0){
                                    // TODO: clear resources
                                    // workers.forEach( (worker) => {
                                    //     worker.terminate()
                                    // })
                                    // workerStatus = []
                                }

                                // send the result to the be processed and rendered on the map
                                TileProcessor.extractModelResponse(result, resolution, leftBottomCoord, detectedFeatureLayer)
                            }
                        }

                        // const result = await runModelFn(image.src);
                        // processModelResultsFn(result);

                    }
                } catch (e) {
                    processModelResultsFn(e)
                }
            }
            // console.log('Number of Tile = ', TileProcessor.tileCounter)
        }
        return onTileLoadEnd;
    }

}
