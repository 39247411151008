import {InferenceSession, Tensor} from 'onnxruntime-web';
export default class Model {

    constructor(backendMode, tensorFormat, loadingFn) {
        this.options = {executionProviders: [backendMode]}
        this.tensorOptions = {tensorFormat: tensorFormat}
        this.loadingFn = loadingFn
    }

    async loadModel(modelFile) {
        this.session = await InferenceSession.create(modelFile, this.options)
        this.inputName = this.session.inputNames[0]
    }

    async runModel(image) {
        try {
            if (this.loadingFn instanceof Function)
                this.loadingFn()
            const inputTensor= await Tensor.fromImage(image, this.tensorOptions)
            return await this.session.run({[this.inputName]: inputTensor})
        } catch (e) {
            throw new Error(`Failed to run model: ${e}`)
        }
    }

    getDescription() {
        return `Backend: ${this.options.executionProviders}<br />Inputs: ${this.session.inputNames}<br />Outputs: ${this.session.outputNames}`
    }
}
