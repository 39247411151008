import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps.js';
import {Style, Stroke, Fill, Text} from 'ol/style';
import Model from '../onnx/model';
import {TileProcessor} from './mapProcessing/TileProcessor';
import {ProcessedTileCache} from './mapProcessing/ProccessedTileCache';
import {defaults as defaultControls} from 'ol/control.js';
import Sidebar from '../assets/js/ol3-sidebar';
import {fromLonLat, toLonLat} from "ol/proj";
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

const loadedModels = Array();
const loadedTileEventHandlers = Array();
const loadedClickEventHandlers = Array();
let currentModelFile = '';
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
let state = {
    lat: 49.552758,
    lon: 8.407197,
    zoom: 18
}
TileProcessor.tileCounter = 0

const bingSource = new BingMaps({
    key: import.meta.env.VITE_BING_KEY,
    imagerySet: 'Aerial',
    // use maxZoom 19 to see stretched tiles instead of the BingMaps
    // "no photos at this zoom level" tiles
    maxZoom: 19
});

const bingLayer = new TileLayer({
    visible: true,
    preload: 0,
    source: bingSource
});

const boxLayer = new VectorLayer({
    source: new VectorSource(),
    style: new Style({
        stroke: new Stroke({
            color: 'yellow',
            width: 2
        }),
    })
})

function detectFeatureStyle(feature) {
    return new Style({
        stroke: new Stroke({
            color: 'red',
            width: 2
        }),
        fill: new Fill({
          color: 'rgba(255,0,0,0.2)',
        }),
        text: new Text({
            // font: '16px sans-serif',
            fill: new Fill({ color: '#000000' }), // Set the text color
            stroke: new Stroke({ color: '#FFFFFF', width: 2 }), // Set the text stroke
            textAlign: 'center',
            justify: 'center',
            text: `${feature.get('confidenceScore')}`
            // padding: [2, 2, 2, 2],
        })
    })
}

const detectedFeatureLayer = new VectorLayer({
  source: new VectorSource(),
  style: detectFeatureStyle
})

const map = new Map({
    controls: defaultControls().extend([new Sidebar({element: 'sidebar', position: 'left'})]),
    target: 'map',
    layers: [
        bingLayer
    ],
});

map.addLayer(boxLayer)
map.addLayer(detectedFeatureLayer)
setZoomInfo('secondary', `Current zoom level: ${Math.round(map.getView().getZoom())}`)

map.getView().on('change:resolution', (event) => {
    setZoomInfo('secondary', `Current zoom level: ${Math.round(map.getView().getZoom())}`)
});

map.on('moveend', (event) => {
    // console.log(event.target.getView().getCenter())
    let [lon, lat] = toLonLat(event.target.getView().getCenter())
    lon = lon.toFixed(5)
    lat = lat.toFixed(5)
    const zoom = Math.round(event.target.getView().getZoom())
    updateUrl(lat, lon, zoom)
    setZoomInfo('secondary', `Current zoom level: ${Math.round(event.target.getView().getZoom())}`)

    TileProcessor.tileCounter = 0
})

initUrlParams()

/**
 * initial state with URL params 
 * if not params in URL then initializes with state's initial values
 */
function initUrlParams() {
    // map param
    if(! urlParams.has('lat'))
        urlParams.append('lat', state.lat)
    if(! urlParams.has('lon'))
        urlParams.append('lon', state.lon)
    if(! urlParams.has('zoom'))
        urlParams.append('zoom', state.zoom)

    // console.log(urlParams.get('lat'), urlParams.get('lon'), parseInt(urlParams.get('zoom')))
    // map.setView([urlParams.get('lat'), urlParams.get('lon')], parseInt(urlParams.get('zoom')))
    map.setView(new View({
        center: fromLonLat([urlParams.get('lon'), urlParams.get('lat')]),
        zoom: urlParams.get('zoom')
    }))
}

/**
 * Updates URL in browser
 * Assumption: store is updated
 */
function updateUrl(lat, lon, zoom) {
    // const state = store.getState().map
    // const filterState = store.getState().filter
    // const countryState = store.getState().country
    // console.log('>>> updateUrl >>> \n', state)
    window.history.pushState('data', '', `?lat=${lat}&lon=${lon}&zoom=${zoom}`)
}

function loadModelFile() {
    deactivateRealtimeMode()
    const input = document.getElementById('modelFile')
    if (input.files.length < 1) {
        setModelInfo('danger', 'Select a valid ONNX file!')
        return
    }
    currentModelFile = input.files[0]
    loadModel()
}

async function loadModel() {
    deactivateRealtimeMode()
    loadedClickEventHandlers.forEach(eventHandler => map.un('click', eventHandler))
    setModelLoading()
    if (!currentModelFile) {
        setModelInfo('danger', 'Select a valid ONNX file!')
        return
    }
    const selectedFile = await currentModelFile.arrayBuffer()
    while (loadedModels.length) loadedModels.pop()
    const backendMode = getSwitch('webGLMode') ? 'webgl' : 'wasm'
    const tensorFormat = getSelect('tensorFormat')
    const model = new Model(backendMode, tensorFormat, setInferenceLoading)
    try {
        await model.loadModel(selectedFile)
        loadedModels.push(model)
        setModelInfo('success', model.getDescription())

        const eventHandler = TileProcessor.getClickedBoxEventHandler(model.runModel.bind(model), handleModelResult, 256, 256, bingLayer, boxLayer, detectedFeatureLayer, tensorFormat)
        map.on('click', eventHandler)
        loadedClickEventHandlers.push(eventHandler)
    } catch (e) {
        let message = 'Failed to load model.'
        if (backendMode === 'webgl' && e instanceof TypeError) {
            message += '<br />Setting backend to WebGL may cause this error. Try turning off WebGL backend mode.'
        } else {
            message += '<br />Error: ' + e
        }
        setModelInfo('danger', message)
    }
}

function handleModelResult(result) {
    if (result instanceof Error) {
        setInferenceInfo('danger', result)
    } else {
        if(Number(result.num_dets.data[0]) === 0 ){
            const noFeatureString = 'No feature detected'
            setInferenceInfo('success', `<pre>${noFeatureString}</pre>`)
        } else {
            const jsonString = JSON.stringify(convertModelResult(result), function(k, v){
                return v instanceof Array ? JSON.stringify(v).replace(/,/g, ', ') : v
            }, 2)
                .replace(/"([^"]+)":/g, '$1:')
                .replace(/"(\[[^"]+\])"/g, '$1')
            setInferenceInfo('success', `<pre>${jsonString}</pre>`)
        }
    }
    return result
}

function convertModelResult(data){
    Object.keys(data).forEach((key) => {
        const tmp = []
        if (data[key].type === 'int64') {
            data[key].data.forEach((element) => tmp.push(parseInt(element.toString())))
        }
        else {
            data[key].data.forEach((element) => tmp.push(element))
        }
        data[key].data = tmp
    })
    return data
}

function setModelLoading() {
    setModelInfo('secondary', '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>')
}

function setModelInfo(mode, message) {
    const box = document.getElementById('modelInfo')
    box.classList.remove('alert-secondary', 'alert-success', 'alert-danger')
    box.classList.add('alert-' + mode)
    box.innerHTML = (currentModelFile ? `Model: ${currentModelFile.name}<br />` : '') + message
}

function setInferenceLoading() {
    setInferenceInfo('secondary', '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>')
}

function setInferenceInfo(mode, message) {
    const box = document.getElementById('inferenceInfo')
    box.classList.remove('alert-secondary', 'alert-success', 'alert-danger')
    box.classList.add('alert-' + mode)
    box.innerHTML = message
}

function getSwitch(id) {
    return document.getElementById(id).checked
}

function getSelect(id) {
    return document.getElementById(id).value
}

function setSwitch(id, value) {
    document.getElementById(id).checked = value
    document.getElementById(id).blur()
}

function tensorFormatChanged() {
    if (loadedModels.length > 0 || currentModelFile)
        loadModel()
}

function toggleBackendMode() {
    getSwitch('webGLMode') ? setSwitch('webGLMode', true) : setSwitch('webGLMode', false)
    if (loadedModels.length > 0 || currentModelFile)
        loadModel()
}

function toggleRealtimeMode() {
    getSwitch('rtMode') ? activateRealtimeMode() : deactivateRealtimeMode()
}

function toggleRealtimeModeWithWorkers() {
    getSwitch('rtModeWithWorkers') ? activateRealtimeModeWithWorkers() : deactivateRealtimeModeWithWorkers()
}
function activateRealtimeMode() {
    if (loadedModels.length < 1) {
        setModelInfo('danger', 'No model loaded, RT mode not possible!')
        deactivateRealtimeMode()
        return
    }
    const model = loadedModels[0]
    const processedTileCache = new ProcessedTileCache()
    const eventHandler = TileProcessor.getTileLoadEventHandler(model.runModel.bind(model), handleModelResult, map, detectedFeatureLayer, bingSource, processedTileCache)
    bingSource.on('tileloadend', eventHandler)
    bingSource.refresh()
    loadedTileEventHandlers.push(eventHandler)
}

async function activateRealtimeModeWithWorkers() {
    console.log('>>> activateRealtimeModeWithWorkers ')
    if (loadedModels.length < 1) {
        setModelInfo('danger', 'No model loaded, RT mode not possible!')
        deactivateRealtimeModeWithWorkers()
        return
    }
    //const model = loadedModels[0]
    const processedTileCache = new ProcessedTileCache()
    // const eventHandler = TileProcessor.getTileLoadEventHandler(model.runModel.bind(model), handleModelResult, map, detectedFeatureLayer, bingSource, processedTileCache)
    // since model cant be directly sent to worker so sent the file as arraybuffer
    const modelFile = await currentModelFile.arrayBuffer()
    const backendMode = getSwitch('webGLMode') ? 'webgl' : 'wasm'
    const tensorFormat = getSelect('tensorFormat')
    // const model = new Model(backendMode, tensorFormat, setInferenceLoading)
    try {
        // await model.loadModel(modelFile)
        const eventHandler = TileProcessor.getTileLoadEventHandlerWithWorkers(
            //model.runModel.bind(model),
            handleModelResult,
            map,
            detectedFeatureLayer,
            bingSource,
            processedTileCache,
            modelFile,
            backendMode,
            tensorFormat
        )
        bingSource.on('tileloadend', eventHandler)
        bingSource.refresh()
        loadedTileEventHandlers.push(eventHandler)
    } catch (err) {
        console.error(err)
    }

}

function deactivateRealtimeMode() {
    loadedTileEventHandlers.forEach(eventHandler => bingSource.un('tileloadend', eventHandler))
    setSwitch('rtMode', false)
}

function deactivateRealtimeModeWithWorkers() {
    loadedTileEventHandlers.forEach(eventHandler => bingSource.un('tileloadend', eventHandler))
    setSwitch('rtModeWithWorkers', false)
}

function clearOutputLayer() {
    boxLayer.getSource().clear()
    detectedFeatureLayer.getSource().clear()
}

function setZoomInfo(mode, message) {
    const zoomLevel = document.getElementById('zoomLevel')
    zoomLevel.classList.remove('alert-secondary', 'alert-success', 'alert-danger')
    zoomLevel.classList.add('alert-' + mode)
    zoomLevel.innerHTML = (currentModelFile ? `Model: ${currentModelFile.name}<br />` : '') + message
}

document.getElementById('modelFileButton').onclick = loadModelFile
document.getElementById('rtMode').onclick = toggleRealtimeMode
document.getElementById('rtModeWithWorkers').onclick = toggleRealtimeModeWithWorkers
document.getElementById('webGLMode').onclick = toggleBackendMode
document.getElementById('tensorFormat').onchange = tensorFormatChanged
document.getElementById('clearButton').onclick = clearOutputLayer
