
export class ProcessedTileCache {

  // Map<any, Map<any, Set>>
  outerMap = new Map()

  addTile(tileCoord){
    const [z,x,y] = tileCoord;
    
    if(!this.outerMap.has(z)){
        this.outerMap.set(z, new Map());
    }
    if(!this.outerMap.get(z).has(x)){
        this.outerMap.get(z).set(x, new Set())
    }
    this.outerMap.get(z).get(x).add(y);

    // console.log('>>> addTile ', this.outerMap)
  }

  hasTile(tileCoord){
    const [z,x,y] = tileCoord;
    return this.outerMap.get(z)?.get(x)?.has(y);
  }
}